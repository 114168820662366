export const setSelectedUserId = (store, selectedUserId) => {
  store.setState({ selectedUserId });
};

export const setSelectedUser = (store, selectedUser) => {
  store.setState({ selectedUser });
};

export const updateUsers = (store, users) => {
  store.setState({ users });
};

export const updateUserList = (store, newUser) => {
  const users = [...store.state.users];

  users.forEach((currentUser, index) => {
    if (currentUser.id === newUser.id) {
      users[index] = newUser;
    }
  });

  store.setState({ users });
};
