import React, { Component } from 'react';
import dateFormat from 'dateformat';

class Timer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: this.getElapsedTime(),
    };
  }

  componentDidMount() {
    this.tick = setInterval(() => {
      this.setState({ date: this.getElapsedTime() });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.tick);
  }

  getElapsedTime = () => {
    if (this.props.dateTime !== null) {
      const differenceInMiliseconds = new Date() - new Date(this.props.dateTime);
      const differenceInSeconds = dateFormat(new Date(differenceInMiliseconds), 'UTC:MM:ss');
      const differenceInHours = Math.floor(differenceInMiliseconds / 1000 / 3600);

      return differenceInHours + ':' + differenceInSeconds;
    }

    return dateFormat(new Date(), 'HH:MM:ss');
  }

  render() {
    return (
      <>
        {this.state.date}
      </>
    );
  }
}

export default Timer;