import axios from 'axios';

export const handleStart = (e, selectedUserId, updateUserList, setSelectedUser, setAjaxInProgress, setModalAutoClose, setModalAutoCloseAfterTime) => {
  e.preventDefault();

  sendRequest('start', selectedUserId, updateUserList, setSelectedUser, setAjaxInProgress, setModalAutoClose, setModalAutoCloseAfterTime);
};

export const handleStop = (e, selectedUserId, updateUserList, setSelectedUser, setAjaxInProgress, setModalAutoClose, setModalAutoCloseAfterTime) => {
  e.preventDefault();

  sendRequest('stop', selectedUserId, updateUserList, setSelectedUser, setAjaxInProgress, setModalAutoClose, setModalAutoCloseAfterTime);
};

const sendRequest = (url, selectedUserId, updateUserList, setSelectedUser, setAjaxInProgress, setModalAutoClose, setModalAutoCloseAfterTime) => {
  axios({
    url: process.env.REACT_APP_API_HOST + '/users/' + url,
    method: 'post',
    headers: {
      // TODO: get the token after a user logs in, storing here like this is against everything!!!
      Authorization: 'Bearer ' + localStorage.getItem('apiToken'),
      Accept:'application/json',
    },
    data: {
      user_id: selectedUserId,
    },
  }).then((response) => {
    updateUserList(response.data.data);
    setSelectedUser(response.data.data);
    setModalAutoClose(true);
  }).catch((error) => {
    localStorage.removeItem('apiToken');

    window.location.href = '/login';
  }).then(() => {
    // always executed
    setAjaxInProgress(false);
    setModalAutoCloseAfterTime(false);
  });
};
