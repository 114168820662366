import React from 'react';
import { handleSelectUserClick } from '../handlers/user';

const User = ({ user, setSelectedUserId, setModalLoadingVisible }) => {
  return (
    <div className={'user-container' + (user.tracker ? ' active' : ' inactive')} onClick={e => handleSelectUserClick(e, user.id, setSelectedUserId, setModalLoadingVisible)} role="button" tabIndex="0">
      <div className="user">
        {user.avatar ? (
          <img src={user.avatar} className="user__avatar" alt={user.firstname + ' ' + user.lastname} />
        ) : (
          <div className="user__avatar--missing" />
        )}
      </div>
      <p className="user__name">{user.lastname + ' ' + user.firstname}</p>
    </div>
  );
};

export default User;
