import React from 'react';
import useGlobalHook from 'use-global-hook';
import * as modalActions from '../actions/modal';
import * as userActions from '../actions/user';

const actions = {
  ...modalActions,
  ...userActions,
};

const initialState = {
  users: [],
  selectedUserId: null,
  selectedUser: null,
  ajaxRequestInProgress: false,
  modalAutoClose: false,
  modalAutoCloseAfterTime: false,
  modalLoadingVisible: false,
};

const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;
