import React, { useEffect } from 'react';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { handleStart, handleStop } from '../handlers/tracker';
import useGlobal from '../store';
import { useAuth } from './../hooks/use-auth';
import Timer from '../components/Timer';

var modalCloseTimer = null;
const UsersModal = () => {
  const { apiToken } = useAuth();
  const [{ selectedUser, selectedUserId, ajaxRequestInProgress, modalAutoClose, modalAutoCloseAfterTime, modalLoadingVisible }, globalActions] = useGlobal();

  useEffect(() => {
    if (selectedUserId) {
      axios.get(process.env.REACT_APP_API_HOST + '/users/' + selectedUserId, {
        headers: {
          'Authorization': 'Bearer ' + apiToken,
          'Accept':'application/json',
        },
      }).then((response) => {
        globalActions.setSelectedUser(response.data.data);
        globalActions.setModalAutoCloseAfterTime(true);
      }).catch((error) => {
        localStorage.removeItem('apiToken');
      
        window.location.href = '/login';
      });
    }
    // eslint-disable-next-line
  }, [selectedUserId]);

  if (!selectedUser) {
    if (modalLoadingVisible) {
      return (
        <>
          <div className="user-modal--outer" onClick={(e) => handleClose(e, globalActions.closeModal)} role="button" tabIndex={0}></div>
          <div className="users-modal">
            <div className="modal__wrapper" style={{position: 'relative'}}>
              <Loader visible={true} type="Rings" color="#222A98" height={100} width={100} style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}} />
            </div>
          </div>
        </>
      );
    }

    return null;
  }

  if (modalAutoCloseAfterTime) {
    modalCloseTimer = setTimeout(() => {
      handleCloseOnTimer(globalActions.closeModal);
    }, 60000);
  }

  const handleStartTap = (e) => {
    e.preventDefault();

    globalActions.setAjaxInProgress(true);
    globalActions.setModalAutoClose(false);

    handleStart(e, selectedUserId, globalActions.updateUserList, globalActions.setSelectedUser, globalActions.setAjaxInProgress, globalActions.setModalAutoClose, globalActions.setModalAutoCloseAfterTime);

    if (modalAutoClose) {
      modalCloseTimer = setTimeout(() => {
        handleCloseOnTimer(globalActions.closeModal);
      }, 10000);
    }
  };

  const handleStopTap = (e) => {
    e.preventDefault();

    globalActions.setAjaxInProgress(true);
    globalActions.setModalAutoClose(false);

    handleStop(e, selectedUserId, globalActions.updateUserList, globalActions.setSelectedUser, globalActions.setAjaxInProgress, globalActions.setModalAutoClose, globalActions.setModalAutoCloseAfterTime);

    if (modalAutoClose) {
      modalCloseTimer = setTimeout(() => {
        handleCloseOnTimer(globalActions.closeModal);
      }, 10000);
    }
  };

  const handleCloseOnTimer = (closeModal) => {
    modalCloseTimer = null;

    closeModal();
  };

  const handleClose = (e, closeModal) => {
    e.preventDefault();

    clearTimeout(modalCloseTimer);
  
    closeModal();
  };

  return (
    <>
      <div className="user-modal--outer" onClick={(e) => handleClose(e, globalActions.closeModal)} role="button" tabIndex={0}></div>
      <div className="users-modal">
        <div className="modal__wrapper">
          <div className="user-modal__upper" style={{paddingBottom: '125px'}}>
            {selectedUser.avatar ? (
              <img src={selectedUser.avatar} className="user__avatar user-modal__avatar" alt={selectedUser.firstname + ' ' + selectedUser.lastname} />
            ) : (
              <div className="user__avatar user-modal__avatar user__avatar--missing" />
            )}
            {selectedUser.firstname !== '' ? (
              <p className="user-modal__name">{selectedUser.lastname + ' ' + selectedUser.firstname}</p>
            ) : (
              null
            )}
            {selectedUser.tracker ? (
              <>
                <span className="user-modal__login--description">Bejelentkezés óta eltelt idő:</span>
                <div className="user-modal__login--time">
                  <Timer dateTime={selectedUser.tracker.started} />
                </div>
              </>
            ) : (
              <>
                {/* <span className="user-modal__login--description">Bejelentkezési idő:</span>
                <div className="user-modal__login--time">
                  <Timer dateTime={null} />
                </div> */}
              </>
            )}
            <span className="user-modal__logged--time">Ma összesen: {selectedUser.hours_today}</span>
            {/* <div className="user-modal__upper__line"></div> */}
            {
              selectedUser.tracker && (
                <button className="user-modal__logout" onClick={handleStopTap}>
                  {ajaxRequestInProgress ? (
                    <div style={{display: 'inline-block', position: 'relative', paddingLeft: '52px'}}>
                      <Loader visible={true} type="Rings" color="#ffffff" height={52} width={52} style={{position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)'}} /> Bejelentkezés
                    </div>
                  ) : (
                    <>Kijelentkezés</>
                  )}
                </button>
              )
            }
          </div>
          {
            !selectedUser.tracker && (
              <button className="user-modal__login" onClick={handleStartTap}>
                {ajaxRequestInProgress ? (
                  <div style={{display: 'inline-block', position: 'relative', paddingLeft: '52px'}}>
                    <Loader visible={true} type="Rings" color="#ffffff" height={52} width={52} style={{position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)'}} /> Bejelentkezés
                  </div>
                ) : (
                  <>Bejelentkezés</>
                )}
              </button>
            )
          }
          <button className="user-modal__cancel" onClick={(e) => handleClose(e, globalActions.closeModal)}>Mégse</button>
        </div>
      </div>
    </>
  );
};

export default UsersModal;