export const closeModal = (store) => {
  store.setState({
    selectedUserId: null,
    selectedUser: null,
    modalLoadingVisible: false,
  });
};

export const setAjaxInProgress = (store, state) => {
  store.setState({ ajaxRequestInProgress: state });
};

export const setModalLoadingVisible = (store, show) => {
  store.setState({ modalLoadingVisible: show });
}

export const setModalAutoClose = (store, state) => {
  store.setState({ modalAutoClose: state });
};

export const setModalAutoCloseAfterTime = (store, state) => {
  store.setState({ modalAutoCloseAfterTime: state });
};
