import { useEffect, useState } from 'react';
import { usePusher } from './use-pusher';

export function useChannel(channelName) {
  const { client } = usePusher();
  const [channel, setChannel] = useState(null);

  useEffect(() => {
    if (!client) {
      return;
    }

    const ch = client.subscribe(channelName);

    setChannel(ch);

    return () => ch.unsubscribe();
  }, [client, channelName]);

  return channel;
}