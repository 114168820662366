import React from 'react';

import { usePusher } from '../hooks/use-pusher';
import { useAuth } from '../hooks/use-auth';

import UsersList from './UsersList';
import UsersModal from './UsersModal';
import { ReactComponent as IconLogout} from '../assets/icon-logout.svg';

const Home = () => {
  const { state } = usePusher();
  const { signOut } = useAuth();

  return (
    <>
      <div className={`pusher-state pusher-state--${state}`}></div>
      <IconLogout className="logout" onClick={signOut} />
      <UsersModal />
      <UsersList />
    </>
  );
};

export default Home;
