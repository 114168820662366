import React, { useEffect } from 'react';
import axios from 'axios';
import useGlobal from '../store';
import { useAuth } from './../hooks/use-auth';
import User from './User';
import { useChannel } from '../hooks/use-channel';

const UsersList = () => {
  const channel = useChannel('private-user-list');
  const { apiToken } = useAuth();
  const [users, globalActions] = useGlobal(
    state => state.users,
    actions => actions
  );

  useEffect(() => {
    const getUsers = () => {
      axios.get(process.env.REACT_APP_API_HOST + '/users', {
        headers: {
          'Authorization': 'Bearer ' + apiToken,
          'Accept':'application/json',
        },
      }).then((response) => {
        globalActions.updateUsers(response.data.data);
      }).catch((error) => {
        localStorage.removeItem('apiToken');
        
        window.location.href = '/login';
      });
    };

    if (channel) {
      // Update user list on push
      channel.bind('update', () => {
        getUsers();
      });

      channel.bind('pusher:subscription_succeeded', function() {
        getUsers();
      });
    } else {
      // Get initial user list
      getUsers();
    }
  }, [channel, apiToken]);

  return (
    <>
      <div className="user-list">
        <div className="signed__user-list__container">
          {users.map((user) => <User key={user.id} user={user} setSelectedUserId={globalActions.setSelectedUserId} setModalLoadingVisible={globalActions.setModalLoadingVisible} />)}
        </div>
        <div className="user-list__container">
          {users.map((user) => <User key={user.id} user={user} setSelectedUserId={globalActions.setSelectedUserId} setModalLoadingVisible={globalActions.setModalLoadingVisible} />)}
        </div>
      </div>
    </>
  );
};

export default UsersList;
