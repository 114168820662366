import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { ProvideAuth } from '../hooks/use-auth';
import PrivateRoute from './PrivateRoute';
import './../css/App.css';
import { PusherContext } from '../hooks/use-pusher';
import Login from './Login';
import Home from './Home';

const App = () => {
  return (
    <Router>
      <ProvideAuth>
        <PusherContext>
          <div className="hrsys">
            <Switch>
              <Route exact path="/login" component={Login} />

              <PrivateRoute path="/" component={Home} />
            </Switch>
          </div>
        </PusherContext>
      </ProvideAuth>
    </Router>
  );
};

export default App;
