import React, { useState, useEffect, useContext, createContext } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const history = useHistory();

  const [user, setUser] = useState(null);
  const [apiToken, setApiToken] = useState(localStorage.getItem('apiToken'));
  const [pusherAppKey, setPusherAppKey] = useState(localStorage.getItem('pusherAppKey'));
  const [signinError, setSigninError] = useState(null);

  const signIn = (email, password) => {
    axios.post(process.env.REACT_APP_API_HOST + '/get-token', {
      email,
      password,
    }).then((response) => {
      if (response.status === 200) {
        setApiToken(response.data.api_token);
        localStorage.setItem('apiToken', response.data.api_token);

        setPusherAppKey(response.data.pusher_app_key);
        localStorage.setItem('pusherAppKey', response.data.pusher_app_key);

        // Redirect to home
        history.replace({pathname: '/'});
      }
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          setSigninError('Sikertelen bejelentkezés!');
        }

        // Probably validation error so show that
        if (error.response.status === 422) {
          if (typeof error.response.data.errors !== 'undefined') {
            setSigninError(error.response.data.errors.email[0]);
          } else {
            setSigninError(error.response.data.message);
          }
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    });
  };

  const signOut = () => {
    setApiToken(null);
    localStorage.removeItem('apiToken');

    setPusherAppKey(null);
    localStorage.removeItem('pusherAppKey');

    history.push('/login');
  };

  useEffect(() => {
    if (apiToken) {
      history.push({pathname: '/'});
    } else {
      history.push({pathname: '/login'});
    }
  }, [apiToken]);
  
  // Return the user object and auth methods
  return {
    user,
    apiToken,
    pusherAppKey,
    signIn,
    signOut,
    signinError,
  };
}