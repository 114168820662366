import React, { createContext, useContext, useState, useEffect } from 'react';
import Pusher from 'pusher-js';
import { useAuth } from './../hooks/use-auth';

export function usePusherProvider() {
  const { apiToken, pusherAppKey } = useAuth();
  const [pusher, setPusher] = useState(null);
  const [state, setState] = useState('disconnected');

  useEffect(() => {
    if (!pusherAppKey) {
      return;
    }

    const pusherConnection = new Pusher(pusherAppKey, {
      cluster: 'eu',
      encrypted: true,
      authEndpoint: process.env.REACT_APP_HRSYS_HOST + '/broadcasting/auth',
      auth: {
        headers: {
          'Authorization': 'Bearer ' + apiToken,
        },
      },
    });

    setPusher(pusherConnection);

    pusherConnection.connection.bind('state_change', function(states) {
      // states = {previous: 'oldState', current: 'newState'}
      setState(states.current);
    });

    // eslint-disable-next-line consistent-return
    return () => pusherConnection.disconnect();
  }, [pusherAppKey, apiToken]);

  return {
    client: pusher,
    state,
  };
}

const pusherContext = createContext();

export function PusherContext({ children }) {
  const pusher = usePusherProvider();

  return <pusherContext.Provider value={pusher}>{children}</pusherContext.Provider>;
}

export function usePusher() {
  return useContext(pusherContext);
}
